/* Typography */

@font-face {
  font-family: 'Barabara';
  src: url('./fonts/BARABARAFINALRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Franklin Gothic';
  src: url('./fonts/ITC Franklin Gothic LT Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/montserrat.regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Asap';
  src: url('./fonts/Asap-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Asap-Bold';
  src: url('./fonts/Asap-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  font-family: 'Franklin Gothic',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #33347e;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar { 
  width: 100%;
  padding-left: 12%;
  padding-right: 12%;
  position: relative;
  display: inline-block;
  vertical-align: top;
  height: 74px;
}

li{
  top: 0;
}

.icons{
  font-size: 1.4em;
  padding: 2px;
}

.imgbutton {
  width: 110px;
  position: relative;
  text-align: center;
  align-items: center;
}

.desc {
  color: white;
  font-family: 'Montserrat';
  font-size: 14px;
  padding-left: 36%;
  padding-right: 36%;
  text-align: justify;
}

.img-with-text {
  width: 130px;
  padding: 0px;
  display: inline-block;
  align-items: center;
}

.footer {
  font-size: 1em;
}

.footercopy{
  font-size: 12pt;
  padding: 0;
  margin: 0;
  padding-left: 40%;
  position: relative;
  top: -0.5vw;
}

.footertext {
  font-family: 'Montserrat', sans-serif;
	color: white;
	vertical-align: top;
}

.tourism-decade {
  z-index: 100;
  width: 70px;
}

a {
  margin-block-start: -60px;
  text-decoration: none;
}

.pdflink{
  text-decoration: underline;
  color: white;
}

.footerlinks:hover {
  text-decoration: underline;
  color: #30357C;
}
